import React from "react"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const defaultState = {
    nav: false,
    toggleNav: () => { },
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
    state = {
        nav: false,
    }


    componentDidMount = () => {

        this.targetElement = document.querySelector('#nav');

        const params = new URLSearchParams(window.location.search);
        this.setState({ nav: params.get('nav') === `open` })

        // setForceNavOpenFromUrl(
        //     params.get('nav') === `open`
        // )
    }

    toggleNav = () => {
        let nav = !this.state.nav
        this.setState({ nav })


        if (nav) {
            disableBodyScroll(this.targetElement);
            console.log(`disableBodyScroll`)
        } else {
            clearAllBodyScrollLocks(this.targetElement);
            console.log(`clearAllBodyScrollLocks`)

        }

    }

    render() {
        const { children } = this.props
        const { nav } = this.state
        return (
            <ThemeContext.Provider
                value={{
                    nav,
                    toggleNav: this.toggleNav,
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeContext

export { ThemeProvider }